// // Senior.js


import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import YouTube from 'react-youtube';

function Senior() {
  
  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    fetchVideoData();
  }, []);

  const fetchVideoData = async () => {
    try {
      const playlistId = "PLQK124e_VdFUwXtWXnON6-iISHciTSgwB";
      const apiKey = "AIzaSyCrTKV1CP39b7dBxjc1iAz8MKLENldu_to";
      const maxResults = 200; // Number of videos to fetch
  
      const playlistResponse = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=${maxResults}&key=${apiKey}`);
      const playlistData = await playlistResponse.json();
  
      if (playlistData.items) {
        const fetchedData = playlistData.items.map(item => ({
          videoId: item.snippet.resourceId.videoId,
          captions: [] // Placeholder for captions, you can fetch captions if needed
        }));
  
        setVideoData(fetchedData);
      } else {
        console.error("Error fetching video data: Playlist items not found.");
      }
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  

  const handlePlay = (videoId) => {
    // Pause all videos except the one being clicked
    const iframes = document.querySelectorAll("iframe");
    iframes.forEach((iframe) => {
      const iframeSrc = iframe.src;
      const isPlaying = iframeSrc.includes(videoId);
      if (!isPlaying) {
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    });
  
    // Play the clicked video
    const selectedVideoIframe = document.querySelector(`iframe[src*="${videoId}"]`);
    if (selectedVideoIframe) {
      selectedVideoIframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    }
  };

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <div className="home-content">
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {videoData.map((video, index) => (
              <div key={index} className="iframe">
                <YouTube
                  videoId={video.videoId}
                  opts={{
                    height: '200',
                    width: '300',
                    playerVars: {
                      autoplay: 0,
                      controls: 1
                    }
                  }}
                  onPlay={() => handlePlay(video.videoId)}
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Senior;
