// Home.js

import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import kids from "../../Assets/kids1.png";
import senior from "../../Assets/senior.png";
import Particle from "../Particle";


function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
      <Particle />
        <Container className="home-content">
          <Row style={{ justifyContent: "center"}}>
            <div className="widget">
            <h1><strong className="purple">kids</strong>Wala</h1>
              <Link to="/kids" className="tech-link">
                <img src={kids} alt="kids" className="img-fluid zoom-on-hover" />
              </Link>
            </div>
            <div className="widget">
            <h1><strong className="purple">senior</strong>Wala</h1>
              <Link to="/senior" className="tech-link">
                <img src={senior} alt="senior" className="img-fluid zoom-on-hover" />
              </Link>
            </div>
          </Row>
        </Container>
      </Container>
    </section>
  );
}
  

export default Home;
