// Team.js

import React from "react";
import { Container, Row } from "react-bootstrap";

function Team() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row style={{ justifyContent: "center"}}>
            
          </Row>
        </Container>
      </Container>
    </section>
  );
}
  

export default Team;
